import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconPersonalTasks = (props) => {
  const { width, height } = props;

  return (
    <SvgIcon width={width || 24} height={height || 24} noFill={true} viewBox="0 0 24 24">
      <path
        d="M12.3701 8.87988H17.6201
        M6.37988 8.87988L7.12988 9.62988L9.37988 7.37988
        M12.3701 15.8799H17.6201
        M6.37988 15.8799L7.12988 16.6299L9.37988 14.3799
        M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="currentColor" // Use current container color
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

IconPersonalTasks.propTypes = simpleIconPropsTypes;
